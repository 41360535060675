
import { defineComponent } from 'vue'
import DeviceList from '@/components/DeviceList.vue'
// import { format, parseISO } from 'date-fns'

export default defineComponent({
	name: 'ApDevices',
	components: {
		DeviceList,
	},
	data () {
		return {
			errored: false,
			loading: false,
			devices: [],
		}
	},
	mounted () {
		fetch(`${process.env.VUE_APP_API_URL}/devices`)
			.then(async (response: any) => {
				this.devices = await response.json()
			})
			.catch(() => {
				this.errored = true
			})
			.finally(() => {
				this.loading = false
			})
	},
	methods: {
		selectDevice (/* id: string*/): void {
			// this.$router.push({ name: 'device', params: { id } })
		},
	},
})
